<template>
  <div class="vue-family-tree__branch">
    <div class="vue-family-tree__row">
      <div
        v-for="(block, index) in tree"
        :key="index"
        class="vue-family-tree__col"
        :class="{'vue-family-tree__col_couple': block.firstPerson && block.secondPerson}"
      >
        <div class="vue-family-tree__content">
          <div
            :class="{
              'vue-family-tree__couple': block.firstPerson && block.secondPerson,
              'vue-family-tree__couple_children': block.children && block.children.length > 0
            }"
          >
            <div
              v-if="block.firstPerson"
              :class="`vue-family-tree__person ${ block.secondPerson ? 'left' : 'center' }`"
            >
              <div class="vue-family-tree__card">
                <slot
                  name="card"
                  :item="{
                    ...block.firstPerson,
                    children: block.children,
                  }"
                >
                  <Card
                    :data="block.firstPerson"
                    :type="block.firstPerson.based_type"
                    @click="$emit('card-click', $event)"
                  />
                </slot>
              </div>
            </div>
            <div
              v-if="block.secondPerson"
              class="vue-family-tree__person left"
            >
              <div class="vue-family-tree__card">
                <slot
                  name="card"
                  :item="block.secondPerson"
                >
                  <Card
                    :data="block.secondPerson"
                    :type="block.secondPerson.based_type"
                    @click="$emit('card-click', $event)"
                  />
                </slot>
              </div>
            </div>
          </div>
        </div>
        <VueFamilyTreeBranch
          v-if="block.children"
          :tree="block.children"
          @card-click="$emit('card-click', $event)"
        >
          <template v-slot:card="{item}">
            <slot
              name="card"
              :item="item"
            />
          </template>
        </VueFamilyTreeBranch>
      </div>
    </div>
  </div>
</template>

<script>
import Card from './Card'

export default {
  name: 'VueFamilyTreeBranch',
  components: {
    Card
  },
  props: {
    tree: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $bg:#630104;
  .vue-family-tree {
    &__branch {
      position: relative;
      .vue-family-tree__branch {
        padding-top: 16px;
        .vue-family-tree__col {
          padding-top: 16px;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 16px;
            right: 0;
            border-top: 1px solid $bg;
          }
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            height: 16px;
            width: 1px;
            background-color: $bg;
          }
          &:first-child {
            &:before {
              width: calc(50% + 8px) ;
              border-left: 1px solid $bg;
              border-radius: 8px 0 0 0;
            }
            &:after {
              display: none;
            }
          }
          &:last-child {
            &:before {
              width: calc(50%);
              border-right: 1px solid $bg;
              left: 0;
              right: auto;
              border-radius: 0 8px 0 0;
            }
            &:after {
              display: none;
            }
          }
          &:first-child:last-child {
            padding-top: 0;
            &:before {
              display: none;
            }
            &:after{
              display: none;
            }
          }
          &_couple {
            &:after {
              left: calc(50% - 68px);
            }
            &:first-child {
              &:before {
                width: calc(50% + 68px);
              }
            }
            &:last-child {
              &:before {
                width: calc(50% - 68px);
              }
            }
            &:first-child:last-child {
              padding-left: 146px;
            }
          }
        }
      }
    }
    &__row {
      display: flex;
      justify-content: center;
    }
    &__col {
      position: relative;
      & + .vue-family-tree__col {
        padding-left: 16px;
      }
    }
    &__content {
      display: flex;
      justify-content: center;
      padding-right: 16px;
    }
    &__couple {
      position: relative;
      display: inline-flex;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -16px;
        height: 1px;
        width: 32px;
        background-color: $bg;
      }
      .vue-family-tree__person {
        &:first-child {
          padding-right: 16px;
        }
        &:last-child {
          padding-left: 16px;
        }
      }
      &_children {
        .vue-family-tree__person {
          &:last-child {
            &:before {
              content: '';
              position: absolute;
              background-color: $bg;
            }
            &:after {
              content: '';
              position: absolute;
            }
          }
          &.left {
            &:last-child {
              &:before {
                top: 50%;
                left: 0;
                width: 1px;
                height: calc(100% - 14px);
              }
              &:after {
                top: calc(50% - 3px);
                left: -2px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $bg;
              }
            }
          }
          &.center {
            &:last-child {
              &:before {
                bottom: -16px;
                left: 50%;
                width: 1px;
                height: 18px;
                z-index: -1;
              }
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
    &__person {
      position: relative;
      z-index: 10;
    }
    &__card {
      width: 200px;
    }
  }
</style>
