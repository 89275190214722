<template>
  <div class="shared-detail">
    <el-row
      class="title"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <h3 class="m-0">
        THÔNG TIN CHI TIẾT PHÂN HỆ: {{ hierarchy.name.toUpperCase() }}
      </h3>
    </el-row>
    <div class="shared-index">
      <VueFamilyTree :tree="[treeItems]">
        <template
          v-slot:card="{item}"
        >
          <HierarchyItem
            :data="item"
            :type="hierarchy.hierarchy_type"
            @add-children="handleAddChildren"
            @edit="() => handleEditItem(item)"
            @reload="() => getItems()"
          />
        </template>
      </VueFamilyTree>
    </div>
    <ItemFormPopup
      v-if="formVisible && selectedParent"
      :is-edit="isEdit"
      :visible="formVisible"
      :parent="selectedParent"
      :hierarchy="hierarchy"
      :place-types="placeTypes"
      :type="hierarchy.hierarchy_type"
      :selected-items="hierarchyItems"
      @close="() => formVisible = false"
      @reload="() => getItems()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getHierarchy } from '@/services/hierarchy'
import { getPlaceTypes, getItemChildren } from '@/utils/filters'
import { getHierarchyItem } from '@/services/hierarchy-item'

import HierarchyItem from './components/Item.vue'
import VueFamilyTree from '../../components/family-tree/index.vue'
import ItemFormPopup from './components/popup/ItemForm.vue'

export default {
  name: 'HierarchyDetail',
  components: {
    VueFamilyTree,
    HierarchyItem,
    ItemFormPopup
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      hierarchy: {
        name: ''
      },
      hierarchyItems: [],
      treeConfig: {
        nodeWidth: 240,
        nodeHeight: 56,
        levelHeight: 160
      },
      placeTypes: getPlaceTypes().filter((t) => t.type !== 'tour'),
      formVisible: false,
      selectedParent: null
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      const p = {
        id: this.$route.params.id,
        locale: this.language
      }
      return p
    },
    itemParams() {
      const p = {
        hierarchy_id: this.$route.params.id,
        locale: this.language
      }
      return p
    },
    treeItems() {
      if (!this.hierarchy.name) {
        return {}
      }
      const parent = {
        ...this.hierarchy.based_model,
        based_id: this.hierarchy.based_id,
        path: '1',
        link_path: '',
        pathLevels: [1]
      }
      const allItems = [
        parent,
        ...this.hierarchyItems
      ]
      return getItemChildren(
        parent,
        allItems,
        allItems
      )
    }
  },
  watch: {
    language: 'loadData'
  },
  beforeMount() {
    this.loadData()
    this.getItems()
  },
  methods: {
    getPlaceTypes,
    loadData() {
      this.loading = true
      this.visibleUpdate = false
      getHierarchy(this.params).then((response) => {
        this.hierarchy = response.result[0]
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    getItems() {
      this.loading = true
      this.visibleUpdate = false
      getHierarchyItem(this.itemParams).then((response) => {
        this.hierarchyItems = response.result.map((item) => {
          const description = JSON.parse(item.description)
          return {
            ...item,
            pathLevels: item.path.split('.'),
            description: description ? description.description : description,
            link_path: description ? description.link_path : ''
          }
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleAddChildren(parent) {
      this.selectedParent = parent
      this.isEdit = false
      this.formVisible = true
    },

    handleEditItem(item) {
      this.selectedParent = item
      this.isEdit = true
      this.formVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.shared-detail {
  .shared-index {
    height: calc(100vh - 130px);
    margin-bottom: 0;
    padding: 16px;
    box-sizing: border-box;
  }
}
</style>

<style lang="scss">
.shared-detail {
  .shared-index {
    .tree-container {
      width: 100%;
      height: calc(100vh - 162px);
      .dom-container {
        top: 16px !important;
      }
      .node-slot {
        cursor: default;
      }
    }
    .custom-card {
      display: flex;
      align-items: center;
      width: 220px;
      padding: 16px;
      border: 1px solid #ddd;
      border-radius: 10px;
      box-sizing: border-box;
      background-color: #fff;
      cursor: pointer;
      box-shadow: 0 0 6px 2px rgba(#000, 0);
      transition: box-shadow .2s ease;
      &:hover {
        box-shadow: 0 0 6px 2px rgba(#000, .1);
      }
      &__image {
        flex: 0 0 auto;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #dedede;
        background-size: cover;
        background-position: 50%;
      }
      &__info {
        padding-left: 16px;
      }
      &__name {
        font-weight: 600;
      }
      &__age {
        margin-top: 4px;
        font-size: 12px;
      }
    }
  }
}
</style>
